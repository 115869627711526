import styled from "styled-components";
import Card from "components/Generic/Card";
import YoutubeEmbeded from "components/Generic/YoutubeEmbed";
import { devices } from "helpers/devices";
import { ReactComponent as Personagens } from "assets/Personagens.svg";
import { ReactComponent as PersonagensBg } from "assets/PersonagensBg.svg";
import { ReactComponent as Microscopio } from "assets/Microscopio.svg";
import { ReactComponent as Microscopio2 } from "assets/Microscopio2.svg";
import { ReactComponent as Monitor } from "assets/Monitor.svg";
import { ReactComponent as Atomo } from "assets/Atomo.svg";

const Section = styled.section`
  /* display: flex;
    justify-content: center;
    align-items: center; */
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem ${props => props.theme.margin_from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem ${props => props.theme.margin_from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    display: grid;
    padding: 0 ${props => props.theme.margin_from_sides};
  }

  @media ${devices.desktop} {
    padding: 0 calc(${props => props.theme.margin_from_sides} + 4rem);
  }

  @media ${devices.bigDesktop} {
    padding: 0 ${props => props.theme.margin_from_sides};
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.color_text1};
  font-size: ${props => props.theme.font_huge};

  @media ${devices.phoneOnly} {
    font-size: 2rem;
    text-align: center;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
    text-align: center;
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    font-size: 2.5rem;
  }
`;

const SubText = styled.p`
  color: ${props => props.theme.color_text3};
  font-size: ${props => props.theme.font_medium};
  margin-top: 20px;

  @media ${devices.phoneOnly} {
    text-align: start;
    font-size: 0.7rem;
  }

  @media ${devices.tabletPortrait} {
    text-align: start;
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

const SubText2 = styled.p`
  color: ${props => props.theme.color_text3};
  font-size: ${props => props.theme.font_medium};

  @media ${devices.phoneOnly} {
    font-size: 0.7rem;
    text-align: start;
  }

  @media ${devices.tabletPortrait} {
    text-align: start;
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

const Left = styled.aside`
  margin-right: 4rem;
  position: relative;
  bottom: 60px;
  grid-row-start: 1;
  grid-column-start: 1;

  @media ${devices.phoneOnly} {
    bottom: 0;
    margin: 1rem;
  }

  @media ${devices.tabletPortrait} {
    bottom: 0;
    margin: 1rem;
  }

  @media ${devices.tabletLandscape} {
    bottom: 60px;
    margin: 0;
    margin-right: 4rem;
  }
`;

const Right = styled.div`
  position: relative;
  margin-top: 4rem;
  grid-row-start: 1;
  grid-column-start: 2;

  @media ${devices.phoneOnly} {
    margin: 1rem;
  }

  @media ${devices.tabletPortrait} {
    margin: 1rem;
  }

  @media ${devices.tabletLandscape} {
    margin: 0;
    margin-top: 4rem;
  }
`;

const YoutubeContainer = styled.div`
  width: 100%;
  height: auto;
  border: none;
`;

const CardItemContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  width: 100%;

  & div {
    padding-left: 2rem;
  }

  & svg {
    max-width: 60px;
    min-width: 60px;
    height: 60px;
  }

  @media ${devices.phoneOnly} {
    & div {
      padding-left: 1.5rem;
    }

    & svg {
      max-width: 40px;
      min-width: 40px;
      height: 40px;
    }
  }

  @media ${devices.tabletPortrait} {
    & div {
      padding-left: 1.5rem;
    }

    & svg {
      max-width: 50px;
      min-width: 50px;
      height: 50px;
    }
  }

  @media ${devices.tabletLandscape} {
    & div {
      padding-left: 1.5rem;
    }

    & svg {
      max-width: 50px;
      min-width: 50px;
      height: 50px;
    }
  }

  @media ${devices.desktop} {
    & div {
      padding-left: 2rem;
    }

    & svg {
      max-width: 60px;
      min-width: 60px;
      height: 60px;
    }
  }
`;

const CardText = styled.p`
  color: ${props => props.theme.color_text3};

  @media ${devices.phoneOnly} {
    font-size: 0.7rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

const DivImg = styled.div`
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
`;

const LabImg = styled(Personagens)`
  position: absolute;
  width: 500px;
  height: auto;
  left: -5%;
  bottom: -6.6%;
  z-index: 1;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: none;
  }

  @media ${devices.desktop} {
    display: block;
  }
`;

const LabImgBg = styled(PersonagensBg)`
  position: absolute;
  width: 500px;
  height: auto;
  left: -10%;
  bottom: -5%;
  z-index: 1;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: none;
  }

  @media ${devices.desktop} {
    display: block;
  }
`;

const Conheca = (): JSX.Element => {
  const MarginContainer = ".2rem 1rem";

  return (
    <Section className="Conheca" id="2">
      <Container>
        <Right>
          <Title>Conheça a TRB</Title>
          <YoutubeContainer>
            <YoutubeEmbeded link="https://www.youtube.com/watch?v=1kWKkjBPBX8" />
          </YoutubeContainer>
          <SubText>
            Fundado em Genebra (1980), pelo Dr. Guido de Napoli, o Grupo TRB
            CHEMEDICA está presente em mais de 40 países. No Brasil desde 1989 e
            tem continuado a crescer e se desenvolver devido ao rigor e
            criatividade de seus pesquisadores e colaboradores no mundo todo.
          </SubText>
          <SubText2>
            É atualmente uma das líderes do mercado no segmento de
            biotecnologia, com um Pipeline promissor com mais de 17 moléculas
            atualmente em pesquisa para o desenvolvimento de novos produtos.
            Mantendo intercâmbio com as principais universidades do Brasil e do
            mundo, a TRB PHARMA acredita e preza na qualidade e pesquisa como
            processo contínuo.
          </SubText2>
          <SubText2>
            A TRB entende que o crescimento econômico deve estar ligado à
            preservação do meio ambiente e a sustentabilidade social. Visando
            crescimento sustentável, agregar valor socioambiental, minimizando
            qualquer dano de sua atuação. Compromisso assumido com ética,
            transparência e profissionalismo. Ciência e Saúde como Princípio.
          </SubText2>
        </Right>
        <Left>
          <Card margin={MarginContainer} padding="3rem 2rem">
            <CardItemContainer>
              <Microscopio />
              <div>
                <CardText>
                  Controle rigoroso é o nosso marco de qualidade
                </CardText>
              </div>
            </CardItemContainer>
            <CardItemContainer>
              <Microscopio2 />
              <div>
                <CardText>
                  Agregar valor socioambiental minimizando qualquer dano
                  resultante de sua atuação
                </CardText>
              </div>
            </CardItemContainer>
            <CardItemContainer>
              <Monitor />
              <div>
                <CardText>
                  Inovar com rigor em estratégias terapêuticas é fundamental
                </CardText>
              </div>
            </CardItemContainer>
            <CardItemContainer>
              <Atomo />
              <div>
                <CardText>Fazendo a Ciência e da Saúde Princípio</CardText>
              </div>
            </CardItemContainer>
          </Card>
        </Left>
        <DivImg>
          <LabImgBg />
          <LabImg />
        </DivImg>
      </Container>
    </Section>
  );
};

export default Conheca;
